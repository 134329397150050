import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // Brand purple
        primary: "#51438A",
        // Brand blue
        secondary: "#2E6FAA",
        // Light grey used throughout design
        info: "#FAFAFA",
        // Green used in 2L path
        accent: "#5C9E44",
        // Red Box
        error: "#FAC4D1",
        // Green box
        success: "#E2F1D8",
        // Blue box
        warning: "#DFECF1",
      },
    },
  },
});
