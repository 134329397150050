import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import { authGuard } from "@/auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/home",
    name: "home",
    component: HomeView,
    meta: {
      title: "CAR T Patient Pathways | Gilead | Home",
    },
  },
  {
    path: "/build-path",
    name: "buildPath",
    component: function () {
      return import(
        /* webpackChunkName: "build-path" */ "../views/BuildPathView.vue"
      );
    },
    meta: {
      title: "CAR T Patient Pathways | Gilead | Build Path",
    },
  },
  {
    path: "/view-sample-path",
    name: "viewSamplePath",
    component: function () {
      return import(
        /* webpackChunkName: "view-sample-path" */ "../views/SamplePathView.vue"
      );
    },
    meta: {
      title: "CAR T Patient Pathways | Gilead | View Sample Path",
    },
  },
  {
    path: "/abbreviations",
    name: "abbreviations",
    component: function () {
      return import(
        /* webpackChunkName: "abbreviations" */ "../views/AbbreviationsView.vue"
      );
    },
    meta: {
      title: "CAR T Patient Pathways | Gilead | Abbreviations",
    },
  },
  {
    path: "/guidelines",
    name: "cdfGuidelines",
    component: function () {
      return import(
        /* webpackChunkName: "guidelines" */ "../views/CDFGuidelinesView.vue"
      );
    },
    meta: {
      title:
        "CAR T Patient Pathways | Gilead | Guidelines and Eligibility Criteria",
    },
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Enable router guard for authentication. Comment out to disable.
router.beforeEach(authGuard);

const DEFAULT_TITLE = "CAR T Patient Pathways | Gilead";
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
});

export default router;
