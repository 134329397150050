// Vue Core
import Vue from "vue";
import App from "./App.vue";

// Vue Router
import router from "./router";
import VueGtm from '@gtm-support/vue2-gtm';

// Vuetify
import vuetify from "./plugins/vuetify";

// Pinia
import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// Auth0 Plugin
import { Auth0Plugin } from "./auth";

// GTM
Vue.use(VueGtm, {
  id: 'GTM-TT6GRMLJ',
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
});

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  pinia,
  vuetify,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
