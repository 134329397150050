<template>
  <v-container fluid tag="section" class="fill-height py-0">
    <v-row class="fill-height">
      <v-col cols="8" class="hero__col">
        <h2 class="hero-headline">Focused on Survival.</h2>
        <h3 class="hero-subheading">
          Build a referral pathway for patients diagnosed with large B-cell
          lymphoma (LBCL).
        </h3>
        <v-btn
          class="rounded-pill mt-10"
          width="min-content"
          x-large
          color="white"
          :to="{ name: 'buildPath' }"
          exact
        >
          Start Build Path
        </v-btn>
        <v-spacer></v-spacer>

        <a
          href="https://gileadmed.info/product/yescarta"
          target="_blank"
          class="mt-5"
        >
          <img
            max-width="100%"
            width="250px"
            alt="Yescarta Logo"
            src="@/assets/img/yescarta_logo_transparent.png"
          />
        </a>
      </v-col>

      <v-col cols="4" class="content__col d-flex flex-column">
        <v-spacer />
        <small>
          <p>
            Yescarta<sup>®</sup> <small>▼</small>(axicabtagene ciloleucel) is
            indicated for the treatment of adult patients with diffuse large
            B-cell lymphoma (DLBCL) and high-grade B-cell lymphoma (HGBL) that
            relapses within 12 months from completion of, or is refractory to,
            first-line chemoimmunotherapy.
          </p>
          <p>
            Yescarta<sup>®</sup> <small>▼</small>(axicabtagene ciloleucel) is
            indicated for the treatment of adult patients with relapsed or
            refractory (r/r) DLBCL and primary mediastinal large B-cell lymphoma
            (PMBCL), after two or more lines of systemic therapy.
          </p>
          <p>
            Great Britain & Northern Ireland: <small>▼</small>Additional
            monitoring required. Adverse events should be reported. For Great
            Britain & Northern Ireland, reporting forms and information can be
            found at
            <a href="www.mhra.gov.uk/yellowcard" target="_blank">
              www.mhra.gov.uk/yellowcard
            </a>
            or via the Yellow Card app (download from the Apple App Store or
            Google Play Store). Adverse events should also be reported to Gilead
            to
            <a href="mailto:safety_FC@gilead.com">safety_FC@gilead.com</a> or
            <a href="tel:4401223897500">+44 (0) 1223 897500.</a>
          </p>
          <p>
            Republic of Ireland: <small>▼</small>Additional monitoring required.
            Adverse events should be reported. For Ireland, reporting forms and
            information can be found at
            <a href="www.hpra.ie" target="_blank">www.hpra.ie</a> and can be
            reported to HPRA on <a href="tel:35316764971">+353 1 6764971</a>.
            Adverse events should also be reported to Gilead to
            <a href="mailto:safety_FC@gilead.com">safety_FC@gilead.com</a> or
            <a href="tel:4401223897500">+44 (0) 1223 897500</a>.
          </p>
          <p>
            This is a promotional tool and Yescarta Prescribing Information for
            GB can be found
            <a href="https://www.emcpi.com/pi/35345" target="_blank">here</a>
            and information for Northern Ireland and Ireland can be found
            <a href="https://www.emcpi.com/pi/ni/383" target="_blank">here</a>.
          </p>
          <p class="mt-3">
            This tool is funded and developed by Gilead Sciences Ltd., in
            collaboration with CAR T referral clinicians
          </p>
          <p class="font-weight-bold mt-3">UK-YES-0826, Sept 2023</p>
        </small>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useTreatmentPathStore } from "../stores/treatmentPath";

export default {
  name: "HomeView",
  setup() {
    const treatmentPathStore = useTreatmentPathStore();
    return { treatmentPathStore };
  },
  mounted() {
    if (this.$route.query.code!='') {
      if (this.$gtm.enabled()) {
        this.$gtm.trackEvent({
          event: 'user_login',
          label: 'User Email',
          value: this.$auth.user.email
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hero__col {
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url("../assets/img/build-path-cell-bg.png"),
    linear-gradient(45deg, #215481 40%, #0a345a);
  background-size: 65%, cover;
  background-position: 80% bottom, center;
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-left: 60px;
  padding-bottom: 70px;

  @media (max-width: 1023px) {
    background-image: linear-gradient(45deg, #215481 40%, #0a345a);
    background-size: cover;
    background-position: center;
  }

  .hero-headline {
    max-width: 90%;
    font-weight: 700;
    font-size: 4.5rem;
    line-height: 1.25;
    color: white;
  }

  .hero-subheading {
    max-width: 90%;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.25;
    color: white;
    margin-top: 16px;
  }
}

.content__col {
  background-color: $off-white;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;

  a {
    font-weight: 500;
    color: $brand-purple;
    text-decoration: none;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
