import { defineStore } from "pinia";
import cardData from "@/data/cards.json";

export const useTreatmentPathStore = defineStore({
  id: "treatmentPath",
  state: () => ({
    oneL: {
      treatmentInitiated: {
        label: "Treatment Initiated",
        options: [
          { text: "R-CHOP" },
          { text: "POLA-R-CHP" },
          { text: "R-mini-CHOP" },
          { text: "R-CODOX-M/R-IVAC" },
          { text: "DA-EPOCH-R" },
          { text: "R-CEOP" },
          { text: "R-GCVP" },
          { text: "Investigational Study" },
          { text: "Palliative Care" },
          { text: "Other" },
        ],
        selected: "",
      },
      patientRisk: {
        label: "Patient Risk",
        options: [{ text: "Low Risk" }, { text: "High Risk" }],
        selected: "",
      },
      patientAssessmentScan: {
        label: "Imaging Scan",
        options: [{ text: "CT Scan" }, { text: "PET Scan" }, { text: "PET/CT Scan" }],
        selected: "",
      },
      patientAssessmentCycles: {
        label: "Patient Assessments",
        options: [
          { divider: true },
          { header: "Scan performed after:" },
          { text: "1 cycle" },
          { text: "2 cycles" },
          { text: "3 cycles" },
          { text: "4 cycles" },
          { text: "5 cycles" },
          { text: "6 cycles" },
        ],
        multiselect: true,
        max: 2,
        selected: [],
      },
      patientStatus: {
        label: "Patient Status",
        options: [
          { text: "Response to treatment/Remission", group: "1B" },
          { text: "Relapse within 12 months*", group: "1A" },
          { text: "Relapse beyond 12 months", group: "1B" },
          { text: "Progressive disease (refractory)", group: "1A" },
          { text: "Partial response (refractory)", group: "1A" },
          { text: "Stable disease (refractory)", group: "1A" },
        ],
        selected: "",
      },
      physicianActions1A: {
        label: "Further Assessments & Next Steps",
        options: [
          { header: "Further Assessments" },
          { text: "CT Scan" },
          { text: "PET Scan" },
          { text: "Biopsy" },
          { text: "Consider ECOG status" },
          {
            text: "Assess risk of CNS involvement & perform lumbar puncture and/or brain imaging as required",
          },
          { divider: true },
          { header: "Next Steps" },
          {
            text: "Contact CAR T treatment centre for CAR T referral and complete CAR T referral form",
          },
          {
            text: "Initiate discussion with CAR T treatment centre prior to initiating any holding therapy",
          },
          { text: "Patient discussion" },
        ],
        multiselect: true,
        selected: [],
      },
      physicianActions1B: {
        label: "Further Assessments & Next Steps",
        options: [
          { header: "Further Assessments" },
          {
            text: "Routine clinical monitoring according to local practice and patient presentation.",
          },
          { text: "CT Scan" },
          { text: "PET Scan" },
          { text: "Biopsy" },
          { text: "Consider ECOG status" },
          {
            text: "Assess risk of CNS involvement & perform lumbar puncture and/or brain imaging as required",
          },
          { divider: true },
          { header: "Next Steps" },
          {
            text: "Consider future treatment options, including CAR T as a potential 3L therapy",
          },
          { text: "Patient discussion" },
        ],
        multiselect: true,
        selected: [],
      },
    },
    twoL: {
      patientTreatmentPath: {
        label: "Patient Treatment Path",
        options: [
          { text: "Non-Transplant Intended" },
          { text: "Transplant Intended" },
        ],
        selected: "",
      },
      patientTreatmentPath2A: {
        label: "Patient Treatment Path",
        options: [{ text: "CAR T" }, { text: "Transplant" }, { text: "Other" }],
        selected: "",
      },
    },
    twoLNonTransplant: {
      treatmentInitiated: {
        label: "2L Treatment Initiated",
        options: [
          { text: "R-GemOx" },
          { text: "R-GDP" },
          { text: "ISRT" },
          { text: "POLA-BR" },
          { text: "R-ICE" },
          { text: "R-DHAP" },
          { text: "Rituximab with bendamustine" },
          { text: "R-ESHAP" },
          { text: "R-Etop" },
          { text: "Transplant" },
          { text: "Investigational Study" },
          { text: "Palliative Care" },
          { text: "Other" },
        ],
        selected: "",
      },
      patientAssessmentScan: {
        label: "Imaging Scan",
        options: [{ text: "CT Scan" }, { text: "PET Scan" }, { text: "PET/CT Scan" }],
        selected: "",
      },
      patientAssessmentCycles: {
        label: "Patient Assessments",
        options: [
          { divider: true },
          { header: "Scan performed after:" },
          { text: "1 cycle" },
          { text: "2 cycles" },
          { text: "3 cycles" },
        ],
        multiselect: true,
        max: 2,
        selected: [],
      },
      patientStatus: {
        label: "Patient Status",
        options: [
          { text: "Response to treatment/Remission" },
          { text: "Refractory or disease progression" },
          { text: "Relapse" },
        ],
        selected: "",
      },
      physicianActions: {
        label: "Further Assessments & Next Steps",
        options: [
          { header: "Further Assessments" },
          { text: "PET Scan" },
          { text: "CT Scan" },
          { text: "Biopsy" },
          { text: "Monitor ECOG Status" },

          { divider: true },
          { header: "Next Steps" },
          {
            text: "Contact CAR T treatment centre for CAR T referral and complete CAR T referral form",
          },
          {
            text: "Initiate discussion with CAR T treatment centre for bridging therapy",
          },
          { text: "Patient discussion" },
        ],
        multiselect: true,
        selected: [],
      },
    },
    twoLTransplant: {
      treatmentInitiated: {
        label: "2L Treatment Initiated",
        options: [
          { text: "R-GemOx" },
          { text: "R-GDP" },
          { text: "ISRT" },
          { text: "POLA-BR" },
          { text: "R-ICE" },
          { text: "R-DHAP" },
          { text: "Rituximab with bendamustine" },
          { text: "R-ESHAP" },
          { text: "R-Etop" },
          { text: "Transplant" },
          { text: "Investigational Study" },
          { text: "Palliative Care" },
          { text: "Other" },
        ],
        selected: "",
      },
      patientAssessmentScan: {
        label: "Imaging Scan",
        options: [{ text: "CT Scan" }, { text: "PET Scan" }, { text: "PET/CT Scan" }],
        selected: "",
      },
      patientAssessmentCycles: {
        label: "Patient Assessments (Pre-Transplant)",
        options: [
          { divider: true },
          { header: "Scan performed after:" },
          { text: "1 cycle" },
          { text: "2 cycles" },
          { text: "3 cycles" },
        ],
        multiselect: true,
        max: 2,
        selected: [],
      },
      patientStatusPreTransplant: {
        label: "Patient Status (Pre-Transplant)",
        options: [
          { text: "Response to treatment/Remission" },
          { text: "Refractory or disease progression" },
          { text: "Relapse" },
        ],
        selected: "",
      },
      physicianActionsNoTransplant: {
        label: "Further Assessments & Next Steps (No Transplant)",
        options: [
          { header: "Further Assessments" },
          { text: "Biopsy" },
          { text: "Monitor ECOG Status" },

          { divider: true },
          { header: "Next Steps" },
          {
            text: "Contact CAR T Treatment Centre for CAR T referral and complete CAR T referral form",
          },
          {
            text: "Initiate discussion with CAR T Treatment Centre for holding therapy",
          },
          { text: "Patient discussion" },
        ],
        multiselect: true,
        selected: [],
      },
      patientStatusPostTransplant: {
        label: "Patient Status (Post-Transplant)",
        options: [
          { text: "Response to treatment/Remission" },
          { text: "Refractory, disease progression or inadequate response" },
          { text: "Relapse" },
        ],
        selected: "",
      },
      physicianActionsPostTransplant: {
        label: "Further Assessments & Next Steps (Post Transplant)",
        options: [
          { header: "Further Assessments" },
          { text: "PET Scan" },
          { text: "CT Scan" },
          { text: "Biopsy" },
          { text: "Monitor ECOG Status" },

          { divider: true },
          { header: "Next Steps" },
          {
            text: "Contact CAR T Treatment Centre for CAR T referral and complete CAR T referral form",
          },
          {
            text: "Initiate discussion with CAR T Treatment Centre for holding therapy",
          },
          { text: "Patient discussion" },
        ],
        multiselect: true,
        selected: [],
      },
    },
  }),
  getters: {
    getOneLTreatment() {
      let cards = [];
      const selection = this.oneL.treatmentInitiated.selected;

      if (selection.length > 0) {
        cards.push(
          cardData.oneL.treatmentInitiated.find(
            (card) => card.heading == selection
          )
        );
      }

      return cards;
    },
    getOneLPatientRisk() {
      let cards = [];
      const selection = this.oneL.patientRisk.selected;

      if (selection.length > 0) {
        cards.push(
          cardData.oneL.patientRisk.find((card) => card.heading == selection)
        );
      }

      return cards;
    },
    getOneLPatientAssessment() {
      let cards = [];
      const scanSelection = this.oneL.patientAssessmentScan.selected;
      const cyclesSelection = this.oneL.patientAssessmentCycles.selected;
      const earlyScan = cyclesSelection.some((selection) =>
        ["1 cycle", "2 cycles", "3 cycles"].includes(selection)
      );

      if (
        scanSelection &&
        scanSelection.length > 0 &&
        cyclesSelection.length > 0
      ) {
        if (scanSelection == "CT Scan") {
          if (earlyScan) {
            cards.push(cardData.oneL.patientAssessments[0]);
          } else {
            cards.push(cardData.oneL.patientAssessments[3]);
          }
        } else if (scanSelection == "PET Scan") {
          if (earlyScan) {
            cards.push(cardData.oneL.patientAssessments[1]);
          } else {
            cards.push(cardData.oneL.patientAssessments[4]);
          }
        } else if (scanSelection == "PET/CT Scan") {
          if (earlyScan) {
            cards.push(cardData.oneL.patientAssessments[2]);
          } else {
            cards.push(cardData.oneL.patientAssessments[5]);
          }
        }

        // Override heading to display actual number of scans selected
        cards[0].heading = `${scanSelection} - ${cyclesSelection.join(", ")}`;
      }

      return cards;
    },
    getOneLPatientStatus() {
      let cards = [];
      const selection = this.oneL.patientStatus.selected;

      if (selection.length > 0) {
        cards.push(
          cardData.oneL.patientStatus.find((card) => card.heading == selection)
        );
      }

      return cards;
    },
    getOneLPhysicianActions1A() {
      let cards = [];
      const selection = this.oneL.physicianActions1A.selected;

      if (selection.length > 0) {
        cardData.oneL.physicianActions1A.forEach(function (card) {
          if (selection.some((selection) => card.heading == selection)) {
            cards.unshift(card);
          } else if (card.showAlways) {
            let tempCard = Object.assign({}, card);
            tempCard.notSelected = true;
            cards.push(tempCard);
          } else return;
        });
      }

      return cards;
    },
    getOneLPhysicianActions1B() {
      let cards = [];
      const selection = this.oneL.physicianActions1B.selected;

      if (selection.length > 0) {
        cardData.oneL.physicianActions1B.forEach(function (card) {
          if (selection.some((selection) => card.heading == selection)) {
            cards.unshift(card);
          } else if (card.showAlways) {
            let tempCard = Object.assign({}, card);
            tempCard.notSelected = true;
            cards.push(tempCard);
          } else return;
        });
      }

      return cards;
    },
    getTwoLPath() {
      let cards = [];
      const selection = this.twoL.patientTreatmentPath.selected;

      if (selection.length > 0) {
        cards.push(
          cardData.twoL.patientTreatmentPath.find(
            (card) => card.heading == selection
          )
        );
      }

      return cards;
    },
    getPatientTreatmentPath2A() {
      let cards = [];
      const selection = this.twoL.patientTreatmentPath2A.selected;

      if (selection.length > 0) {
        cards.push(
          cardData.twoL.patientTreatmentPath2A.find(
            (card) => card.heading == selection
          )
        );
      }

      return cards;
    },
    getTwoLNonTransplantTreatment() {
      let cards = [];
      const selection = this.twoLNonTransplant.treatmentInitiated.selected;

      if (selection.length > 0) {
        cards.push(
          ...cardData.twoL.nonTransplantIntended.treatmentInitiated.filter(
            (card) => card.heading == selection
          )
        );
      }

      return cards;
    },
    getTwoLNonTransplantAssessment() {
      let cards = [];
      const scanSelection =
        this.twoLNonTransplant.patientAssessmentScan.selected;
      const cyclesSelection =
        this.twoLNonTransplant.patientAssessmentCycles.selected;
      const earlyScan = cyclesSelection.some((selection) =>
        ["1 cycle", "2 cycles"].includes(selection)
      );

      if (
        scanSelection &&
        scanSelection.length > 0 &&
        cyclesSelection.length > 0
      ) {
        if (scanSelection == "CT Scan") {
          if (earlyScan) {
            cards.push(
              cardData.twoL.nonTransplantIntended.patientAssessments[0]
            );
          } else {
            cards.push(
              cardData.twoL.nonTransplantIntended.patientAssessments[3]
            );
          }
        } else if (scanSelection == "PET Scan") {
          if (earlyScan) {
            cards.push(
              cardData.twoL.nonTransplantIntended.patientAssessments[1]
            );
          } else {
            cards.push(
              cardData.twoL.nonTransplantIntended.patientAssessments[4]
            );
          }
        } else if (scanSelection == "PET/CT Scan") {
          if (earlyScan) {
            cards.push(
              cardData.twoL.nonTransplantIntended.patientAssessments[2]
            );
          } else {
            cards.push(
              cardData.twoL.nonTransplantIntended.patientAssessments[5]
            );
          }
        }

        // Override heading to display actual number of scans selected
        cards[0].heading = `${scanSelection} - ${cyclesSelection.join(", ")}`;
      }

      return cards;
    },
    getTwoLNonTransplantPatientStatus() {
      let cards = [];
      const selection = this.twoLNonTransplant.patientStatus.selected;

      if (selection.length > 0) {
        cards.push(
          cardData.twoL.nonTransplantIntended.patientStatus.find(
            (card) => card.heading == selection
          )
        );
      }

      return cards;
    },
    getTwoLNonTransplantPhysicianActions() {
      let cards = [];
      const selection = this.twoLNonTransplant.physicianActions.selected;

      if (selection.length > 0) {
        cardData.twoL.nonTransplantIntended.physicianActions.forEach(function (
          card
        ) {
          if (selection.some((selection) => card.heading.toLowerCase() == selection.toLowerCase())) {
            cards.unshift(card);
          } else if (card.showAlways) {
            let tempCard = Object.assign({}, card);
            tempCard.notSelected = true;
            cards.push(tempCard);
          } else return;
        });
      }

      return cards;
    },
    getTwoLTransplantTreatment() {
      let cards = [];
      const selection = this.twoLTransplant.treatmentInitiated.selected;

      if (selection.length > 0) {
        cardData.twoL.transplantIntended.treatmentInitiated.forEach(function (
          card
        ) {
          if (card.heading == selection) {
            cards.unshift(card);
          } else if (card.showAlways) {
            let tempCard = Object.assign({}, card);
            tempCard.notSelected = true;
            cards.push(tempCard);
          } else return;
        });
      }

      return cards;
    },
    getTwoLTransplantAssessment() {
      let cards = [];
      const scanSelection = this.twoLTransplant.patientAssessmentScan.selected;
      const cyclesSelection =
        this.twoLTransplant.patientAssessmentCycles.selected;
      const earlyScan = cyclesSelection.some((selection) =>
        ["1 cycle"].includes(selection)
      );

      if (
        scanSelection &&
        scanSelection.length > 0 &&
        cyclesSelection.length > 0
      ) {
        if (scanSelection == "CT Scan") {
          if (earlyScan) {
            cards.push(cardData.twoL.transplantIntended.patientAssessments[0]);
          } else {
            cards.push(cardData.twoL.transplantIntended.patientAssessments[3]);
          }
        } else if (scanSelection == "PET Scan") {
          if (earlyScan) {
            cards.push(cardData.twoL.transplantIntended.patientAssessments[1]);
          } else {
            cards.push(cardData.twoL.transplantIntended.patientAssessments[4]);
          }
        } else if (scanSelection == "PET/CT Scan") {
          if (earlyScan) {
            cards.push(cardData.twoL.transplantIntended.patientAssessments[2]);
          } else {
            cards.push(cardData.twoL.transplantIntended.patientAssessments[5]);
          }
        }

        // Override heading to display actual number of scans selected
        cards[0].heading = `${scanSelection} - ${cyclesSelection.join(", ")}`;
      }

      return cards;
    },
    getTwoLTransplantPatientStatusPreTransplant() {
      let cards = [];
      const selection = this.twoLTransplant.patientStatusPreTransplant.selected;

      if (selection.length > 0) {
        cards.push(
          cardData.twoL.transplantIntended.patientStatusPreTransplant.find(
            (card) => card.heading == selection
          )
        );
      }

      return cards;
    },
    getTwoLTransplantPhysicianActionsNoTransplant() {
      let cards = [];
      const selection =
        this.twoLTransplant.physicianActionsNoTransplant.selected;

      if (selection.length > 0) {
        cardData.twoL.transplantIntended.physicianActionsNoTransplant.forEach(
          function (card) {
            if (selection.some((selection) => card.heading == selection)) {
              cards.unshift(card);
            } else if (card.showAlways) {
              let tempCard = Object.assign({}, card);
              tempCard.notSelected = true;
              cards.push(tempCard);
            } else return;
          }
        );
      }

      return cards;
    },
    getTwoLTransplantPatientStatusPostTransplant() {
      let cards = [];
      const selection =
        this.twoLTransplant.patientStatusPostTransplant.selected;

      if (selection.length > 0) {
        cards.push(
          cardData.twoL.transplantIntended.patientStatusPostTransplant.find(
            (card) => card.heading == selection
          )
        );
      }

      return cards;
    },
    getTwoLTransplantPhysicianActionsPostTransplant() {
      let cards = [];
      const selection =
        this.twoLTransplant.physicianActionsPostTransplant.selected;

      if (selection.length > 0) {
        cardData.twoL.transplantIntended.physicianActionsPostTransplant.forEach(
          function (card) {
            if (selection.some((selection) => card.heading.toLowerCase() == selection.toLowerCase())) {
              cards.unshift(card);
            } else if (card.showAlways) {
              let tempCard = Object.assign({}, card);
              tempCard.notSelected = true;
              cards.push(tempCard);
            } else return;
          }
        );
      }

      return cards;
    },
  },
  actions: {},
});
