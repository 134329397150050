<template>
  <v-app>
    <!-- Check that the Auth0 SDK client has loaded -->
    <template v-if="!$auth.loading">
      <v-app-bar app color="white" flat>
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img
              alt="Kite Logo"
              class="mr-2"
              contain
              src="../public/GILEAD_KITE_Logo_Horiz.png"
              width="220px"
            />
          </router-link>
        </div>

        <v-spacer></v-spacer>
        <v-tabs
          centered
          slider-color="primary"
          slider-size="4"
          color="#51438a"
          optional
          show-arrows
          :hide-slider="$route.path == '/'"
        >
          <v-tab exact :to="{ name: 'buildPath' }">Build Current Path </v-tab>
          <v-tab exact :to="{ name: 'viewSamplePath' }">Sample Path</v-tab>
          <v-tab exact :to="{ name: 'cdfGuidelines' }"
            >Guidelines and Eligibility Criteria</v-tab
          >
          <v-tab exact :to="{ name: 'abbreviations' }">Abbreviations</v-tab>
          <a
            href="https://www.emcpi.com/pi/35345"
            target="_blank"
            class="v-tab"
          >
            PI - GB
          </a>
          <a
            href="https://www.emcpi.com/pi/ni/383"
            target="_blank"
            class="v-tab"
          >
            PI – Northern Ireland & Ireland
          </a>
          <a
            href="https://www.gilead.co.uk/privacy-statements"
            target="_blank"
            class="v-tab"
          >
            Privacy Policy
          </a>
        </v-tabs>

        <v-spacer></v-spacer>

        <!-- show login when not authenticated -->
        <v-btn v-if="!$auth.isAuthenticated" color="primary" @click="login">
          Log in
        </v-btn>

        <!-- show logout when authenticated -->
        <v-menu
          v-if="$auth.isAuthenticated"
          attach="#logout-anchor"
          bottom
          offset-y
          nudge-bottom="14"
          content-class="pa-3 grey lighten-5"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              class="body-2 text-lowercase dark-grey--text"
              v-bind="attrs"
              v-on="on"
            >
              {{ $auth.user.email }}
              <v-icon size="24" class="ms-2" color="grey">
                mdi-chevron-down
              </v-icon>
              <v-icon size="24" class="ms-2">mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>
          <v-btn block color="primary" class="rounded-pill" @click="logout">
            Logout
          </v-btn>
        </v-menu>
        <div id="logout-anchor"></div>
      </v-app-bar>

      <v-main>
        <v-slide-x-transition mode="out-in">
          <router-view />
        </v-slide-x-transition>
      </v-main>
    </template>
  </v-app>
</template>

<script>
export default {
  name: "App",
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style lang="scss">
.light-grey--text {
  color: $light-grey !important;
}

.dark-grey--text {
  color: $dark-grey !important;
}

.v-toolbar__content {
  border-bottom: 1px solid #e8eae8 !important;

  .v-tab {
    text-transform: initial;
    font-size: 0.875rem;
    color: $dark-grey !important;
    letter-spacing: 0;

    &.v-tab--active {
      font-weight: 700;
    }
  }
}

.grey-line-divider {
  height: 1px;
  background-color: #8d9097;
  max-width: 100%;
}

.green-bg {
  background-color: $green-box;
}

.blue-bg {
  background-color: $blue-box;
}

.red-bg {
  background-color: $red-box;
}

.yellow-bg {
  background-color: $yellow-box;
}

.grey-bg {
  background-color: #eeeeee;
}
</style>
